import NextLink from 'next/link';
import {
  Flex,
  // Icon,
  Text,
} from '@chakra-ui/react';

export default function NavLink({ link, ...rest }: { link: any; rest?: any }) {
  const {
    label,
    // icon,
    href,
  } = link;

  return (
    <NextLink href={href} passHref>
      <a>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          // _hover={{
          //   bg: 'orange.500',
          // }}
          {...rest}
        >
          {/* {icon && <Icon mr="4" fontSize="16" color="black" as={icon} />} */}
          <Text
            color="#D92A1C"
            textAlign="center"
            fontFamily="AMX"
            fontSize="30px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="100%" /* 30px */
          >
            {label}
          </Text>
        </Flex>
      </a>
    </NextLink>
  );
}
