import { HStack, Link, Icon, Image, VStack, Box } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useRouter } from 'next/router';
// import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useModal } from '@context/modalsContext';
import { modalRegisterID } from '@lib/consts_envs';

export default function Header({
  onOpen,
  // isGame = false,
  notIsHome = false,
  ...rest
}: {
  onOpen: any;
  // isGame?: boolean;
  notIsHome?: boolean;
  rest?: any;
}) {
  const [isOnTop, setIsOnTop] = useState(false);
  const { changeModalState } = useModal();
  const router = useRouter();

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setIsOnTop(scrollPosition <= 2);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <HStack
      position={
        notIsHome
          ? 'sticky'
          : {
              base: 'sticky',
              sm: 'sticky', // 480px
              md: 'sticky', // 768px
              lg: 'fixed', // 992px
              xl: 'fixed', // 1280px
              '2xl': 'fixed', // 1536px
            }
      }
      top="0"
      w={'full'}
      px={[2, 2, 2, 2]}
      height={['60px', '90px']}
      zIndex="10"
      alignItems="center"
      bg={
        isOnTop &&  !notIsHome
          ? {
              base: '#D92A1C',
              sm: '#D92A1C', // 480px
              md: '#D92A1C', // 768px
              lg: 'none', // 992px
              xl: 'none', // 1280px
              '2xl': 'none', // 1536px
            }
          : 'lpHeaderScrollColor'
      }
      // backdropFilter={isOnTop ? undefined : 'saturate(180%) blur(5px)'}
      opacity={0.95}
      justifyContent={
        notIsHome
          ? {
            base: 'space-between',
            sm: 'space-between', // 480px
            md: 'space-around', // 768px
            lg: 'space-around', // 992px
            xl: 'space-around', // 1280px
            '2xl': 'space-around', // 1536px
          }:  isOnTop
          ? {
              base: 'space-between',
              sm: 'space-between', // 480px
              md: 'space-between', // 768px
              lg: 'start', // 992px
              xl: 'start', // 1280px
              '2xl': 'start', // 1536px
            }
          : 'space-around'
        // isOnTop || !isGame || !notIsHome
        //   ? {
        //       base: 'space-between',
        //       sm: 'space-between', // 480px
        //       md: 'space-between', // 768px
        //       lg: 'start', // 992px
        //       xl: 'start', // 1280px
        //       '2xl': 'start', // 1536px
        //     }
        //   : 'space-around'
      }
      spacing={6}
      {...rest}
    >
      <HStack
        display={{ base: 'flex', md: 'none' }}
        h={'30px'}
        align={'center'}
      >
        <VStack h={'30px'} justify={'end'}>
          <Icon
            onClick={onOpen}
            color="white"
            h={7}
            w={7}
            aria-label="open menu"
            as={MdMenu}
            zIndex={1}
          />
        </VStack>
        <VStack h={'30px'} align={'center'}>
          <Image
            h={'27.05px'}
            src={'/imgs/oston/claro/name.svg'}
            onClick={() => {
              router.push('/');
            }}
            cursor="pointer"
          />
        </VStack>
      </HStack>
      <HStack
        px={{
          base: '0px',
          sm: '0px', // 480px
          md: '0px', // 768px
          lg: '0vw', // 992px
          xl: '8vw', // 1280px
          '2xl': '8vw', // 1536px
        }}
        spacing={{
          base: '40px',
          sm: '40px', // 480px
          md: '40px', // 768px
          lg: '5vw', // 992px
          xl: '4vw', // 1280px
          '2xl': '3vw', // 1536px
        }}
      >
        <VStack>
          <Image
            display={{ base: 'none', md: 'flex' }}
            w={'89px'}
            h={'32px'}
            src={'/imgs/oston/claro/name.svg'}
            onClick={() => {
              router.push('/');
            }}
            cursor="pointer"
          />
        </VStack>
        <HStack
          zIndex={1}
          spacing={6}
          justifyContent={'space-between'}
          align={'center'}
          display={'flex'}
          minH={'36px'}
        >
          {/* {status === 'unauthenticated' && (
          <Box
            display={{ base: 'none', md: 'flex' }}
            as={motion.div}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
            pr={5}
          >
            <Button
              variant="lpHeaderButton"
              size="lg"
              onClick={() => {
                router.push('/login');
              }}
            >
              Quero participar
            </Button>
          </Box>
        )} */}
          <Link
            onClick={() => {
              // document
              //   .getElementById('how-to-play')
              //   ?.scrollIntoView({
              //     behavior: 'smooth',
              //     block: 'start',
              //     inline: 'nearest',
              //   });
              // var scrollDiv =
              //   (document.getElementById('how-to-play')?.offsetTop || 0) - 30;
              // window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
              router.push('/#how-to-play');
            }}
            variant="lpHeaderLinks"
          >
            Como participar
          </Link>
          <Link
            onClick={() => {
              // document.getElementById('faq')?.scrollIntoView({
              //   behavior: 'smooth',
              //   block: 'start',
              //   inline: 'nearest',
              // });
              // var scrollDiv =
              //   (document.getElementById('faq')?.offsetTop || 0) - 90;
              // window.scrollTo({ top: scrollDiv, behavior: 'smooth' });

              router.push('/faq');
            }}
            variant="lpHeaderLinks"
          >
            Dúvidas
          </Link>
          <Link
            onClick={() => {
              // document.getElementById('regulations')?.scrollIntoView({
              //   behavior: 'smooth',
              //   block: 'start',
              //   inline: 'nearest',
              // });

              // var scrollDiv =
              //   document.getElementById('regulations')?.offsetTop || 0 + 90;
              // window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
              router.push('/regulations');
            }}
            variant="lpHeaderLinks"
          >
            Regulamento
          </Link>
          <Link
            onClick={() => {
              changeModalState(modalRegisterID, true);
            }}
            variant="lpHeaderLinks"
          >
            Ganhadores
          </Link>
        </HStack>

        <Box display={'flex'}>
          <UserProfile />
        </Box>
      </HStack>
    </HStack>
  );
}
