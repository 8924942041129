import {
  Avatar,
  HStack,
  VStack,
  Text,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Image
} from '@chakra-ui/react';
// import { useSession, signOut } from 'next-auth/react';
import router from 'next/router';
import {
  MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { IoPersonSharp } from 'react-icons/io5';
import { useMemo } from 'react';
import { useSession } from '@hooks/useSession';

export default function UserProfile() {
  const { data: session, signOut } = useSession();

  const links = useMemo(
    () => [
      {
        label: 'Minhas jogadas',
        iconUrl: '/imgs/oston/gift.svg',
        link: '/account/prizes',
      },
      {
        label: 'Meus Números da sorte',
        iconUrl: '/imgs/oston/ticket.svg',
        link: '/account/luckynumbers',
      },
      // {
      //   label: 'Meus Pontos',
      //   iconUrl: '/imgs/oston/ticket.svg',
      //   link: '/account/points',
      // },
      // {
      //   label: 'Respostas no Quiz',
      //   iconUrl: '/imgs/oston/chat.svg',
      //   link: '/account/results',
      // },
      {
        label: 'Sair',
        iconUrl: '/imgs/oston/exit.svg',
        onClick: () => signOut(),
      },
    ],
    []
  );

  return (
    <HStack zIndex={1}>
      {/* <IconButton
    size="lg"
    variant="ghost"
    aria-label="open menu"
    icon={<FiBell />}
  /> */}
      {/* {!session && (
        <Button
          variant="lpLoginButton"
          leftIcon={<TbLogout color="black" />}
          colorScheme="orange"
          onClick={() => {
            router.push('/login');
          }}
        >
          Entrar
        </Button>
      )} */}
      {session && (
        <Popover>
          <PopoverTrigger>
            <HStack spacing="1" cursor="pointer">
              <Avatar
                size="sm"
                bg="#FFC722"
                icon={<IoPersonSharp fontSize="1.5rem" color="#DA291C" />}
              />
              <VStack
                display={{ base: 'none', xl: 'flex' }}
                align="flex-start"
                spacing="1px"
                ml="2"
                fontFamily={'mainfont'}
                color="#FFC722"
                opacity={'60%'}
              >
                <Text
                  fontFamily="AMX"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="100%;"
                  color="#FFC722"
                >
                  {session.user?.name ? session.user.name : session.user?.email}
                </Text>
                {/* {session?.user?.phone ?? (
                    <Text color="white" as="i" onClick={() => router.push("/phone")} cursor="pointer" fontSize='sm' >
                      nenhum telefone cadastrado
                    </Text>
                  )} */}
              </VStack>
            </HStack>
          </PopoverTrigger>
          <PopoverContent w={'100vw'} borderRadius={0}>
            <PopoverArrow />
            <PopoverBody w={'full'} bg={'#FFF'}>
              <VStack p={8} spacing={8}>
                {links.map((value) => (
                  <HStack
                    cursor="pointer"
                    onClick={() => {
                      if (value.onClick) {
                        value.onClick();
                      } else if (value.link) {
                        router.push(value.link);
                      }
                    }}
                    key={value.label}
                    justify="space-between"
                    w={'full'}
                  >
                    <HStack
                      fontFamily="AMX"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight="24px"
                      color="#000000"
                    >
                      <Image height="19px" width="19px" src={value.iconUrl} />
                      <Text textAlign="center" verticalAlign="center">
                        {value.label}
                      </Text>
                    </HStack>
                    <Icon as={MdOutlineArrowForwardIos} w={'20px'} />
                  </HStack>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {/* {session && (
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack spacing="1">
                <Avatar
                  size="sm"
                  bg="#FFC722"
                  icon={<IoPersonSharp fontSize='1.5rem'  color='#DA291C' />}
                />
                <VStack
                  display={{ base: 'none', xl: 'flex' }}
                  align="flex-start"
                  spacing="1px"
                  ml="2"
                  fontFamily={'mainfont'}
                  color="#1D1D1D"
                  opacity={'60%'}
                >
                  <Text fontFamily={'mainfont'} color="#1D1D1D" opacity={'80%'}>
                    {session.user.name ? session.user.name : session.user.email}
                  </Text>
                  {/* {session?.user?.phone ?? (
                    <Text color="white" as="i" onClick={() => router.push("/phone")} cursor="pointer" fontSize='sm' >
                      nenhum telefone cadastrado
                    </Text>
                  )} * /}
                </VStack>
              </HStack>
            </MenuButton>
            <MenuList color="#1D1D1D" bg="white">
              <MenuItem
                onClick={() => {
                  router.push('/account/luckynumbers');
                }}
                icon={<IoTicketOutline />}
              >
                {' '}
                <Text fontFamily={'mainfont'} color="#1D1D1D" size={'md'}>
                  {' '}
                  Meus Números da Sorte{' '}
                </Text>
              </MenuItem>
              <MenuDivider display={{ base: 'flex', xl: 'none' }} />
              <MenuItem
                display={{ base: 'flex', xl: 'none' }}
                color="#1D1D1D"
                opacity={'60%'}
                icon={<MdLogout />}
                onClick={() => {
                  signOut();
                }}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
          <Box>
            <FiChevronDown color="black" opacity={'60%'} />
          </Box>
          <Center height="30px" px={2} display={{ base: 'none', xl: 'flex' }}>
            <Divider orientation="vertical" w="1px" h="36px" bgColor="black" />
          </Center>
          <Box w={'5px'} h={'40px'}></Box>
          <Box display={{ base: 'none', xl: 'flex' }}>
            <Icon
              aria-label="sair"
              cursor="pointer"
              color="black"
              opacity={'60%'}
              as={TbLogout}
              w={8}
              h={8}
              onClick={() => {
                signOut();
              }}
            />
          </Box>
        </Flex>
      )} */}
    </HStack>
  );
}
