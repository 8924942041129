import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

import NavLink from '@components/NavLink';
import { VscBook } from 'react-icons/vsc';
import { BsQuestionCircle } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';
import { RxHamburgerMenu } from 'react-icons/rx';
// import {  useSession } from 'next-auth/react';
import { MdGames } from 'react-icons/md';
import { useStages } from '@hooks/useStages';
import { useModal } from '@context/modalsContext';
import { modalRegisterID } from '@lib/consts_envs';
import { useSession } from '@hooks/useSession';

const LinkItems = [
  { label: 'Home', icon: VscBook, href: '/' },
  { label: 'Dúvidas', icon: BsQuestionCircle, href: '/faq' },
  { label: 'Regulamento', icon: IoDocumentOutline, href: '/regulations' },
];

export default function Sidebar({
  onClose,
  isOpen,
  ...rest
}: {
  onClose: any;
  isOpen: boolean;
  rest?: any;
}) {
  const router = useRouter();
  const { status } = useSession();
  const { stages, loadNewStages } = useStages();
  const { changeModalState } = useModal();

  useEffect(() => {
    if (stages != undefined && stages.length <= 0) {
      loadNewStages().then();
    }
  }, [status, stages]);

  useEffect(() => {
    router.events.on('routeChangeComplete', onClose);
    return () => {
      router.events.off('routeChangeComplete', onClose);
    };
  }, [router.events, onClose]);

  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size="full"
    >
      <DrawerOverlay />
      <DrawerContent>
        {/* <DrawerCloseButton /> */}
        <DrawerHeader color="black" fontSize="2xl">
          <HStack onClick={onClose}>
            <Icon
              transform={'rotate(90deg)'}
              as={RxHamburgerMenu}
              color="#D92A1C"
            />
          </HStack>
        </DrawerHeader>

        <DrawerBody {...rest}>
          <VStack
            color="#D92A1C"
            textAlign="center"
            fontFamily="AMX"
            fontSize="30px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="100%"
          >
            {LinkItems.map((link, i) => (
              <VStack key={`link${i}`} onClick={() => onClose()}>
                <NavLink  link={link} />
              </VStack>
            ))}

            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              onClick={() => {
                onClose();
                changeModalState(modalRegisterID, true);
              }}
            >
              <Text>Ganhadores</Text>
            </Flex>
            {status == 'authenticated' &&
              stages != undefined &&
              stages?.length > 0 && (
                <Accordion defaultIndex={[0]} allowMultiple w="full">
                  <AccordionItem border="none" w="full">
                    <AccordionButton
                      color="#D92A1C"
                      textAlign="center"
                      fontFamily="AMX"
                      fontSize="30px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="100%"
                      w="full"
                      justifyContent="space-around"
                    >
                      <AccordionIcon />
                      <Box>Games</Box>
                      <Box w={'10px'}></Box>
                    </AccordionButton>
                    <AccordionPanel
                      display={'flex'}
                      alignItems="center"
                      justifyContent="center"
                      flexDir="column"
                    >
                      {stages.map((stage) =>
                        stage.games.map((game) => (
                          <VStack key={game.idGame}>
                            <NavLink
                              link={{
                                label: game.name,
                                icon: MdGames,
                                href: `/game?${new URLSearchParams({
                                  ig: String(game.idGame),
                                  is: String(stage.id),
                                  tp: String(game.type),
                                  inf: Buffer.from(
                                    JSON.stringify({
                                      rbcode: String(game.rebilling_code),
                                      ipo: game.paid_only ? 'true' : 'false',
                                      us: game.utm_source,
                                      ug: game.utm_game,
                                    }),
                                    'utf8'
                                  ).toString('base64'),
                                })}`,
                              }}
                            />
                          </VStack>
                        ))
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            {/* {status == 'authenticated' && (
              <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                onClick={() => {
                  signOut();
                }}
              >
                <Text>Sair</Text>
              </Flex>
            )} */}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
